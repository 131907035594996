import React from 'react';
import utcToZonedTime from 'date-fns-tz/fp/utcToZonedTime';
import { CurrentUser } from '../../types/CurrentUser';

export type CurrentUserContextType = {
  can: (permission: string) => boolean;
  cannot: (permission: string) => boolean;
  /**
   * Localise a UTC date to the user's selected timezone.
   */
  localiseDate: (utcDate: Date | string) => Date;
} & CurrentUser;

const CurrentUserContext = React.createContext<
  CurrentUserContextType | undefined
>(undefined);

type CurrentUserProviderProps = {
  user: CurrentUser;
  children: JSX.Element;
};

export const useCurrentUser = (): CurrentUserContextType => {
  const context = React.useContext(CurrentUserContext);
  if (!context) {
    throw new Error(
      'useCurrentUser can only be used inside CurrentUserProvider',
    );
  }
  return context;
};

export const CurrentUserProvider: React.FC<CurrentUserProviderProps> = ({
  user,
  children,
}) => {
  const can = (permission: string) => user.permissions[permission] === true;
  const cannot = (permission: string) => !can(permission);

  const value: CurrentUserContextType = {
    ...user,
    can,
    cannot,
    localiseDate: utcToZonedTime(user.tz),
  };
  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};
