import { Tag } from '@chakra-ui/react';
import React from 'react';

export interface QTagProps {
  variantColor?:
    | 'default'
    | 'blue'
    | 'green'
    | 'red'
    | 'yellow'
    | 'orange'
    | 'invertedOrange'
    | 'purple'
    | 'pink'
    | 'gray';
}

export const QTag: React.FC<QTagProps> = (props) => {
  const { variantColor = 'default', ...rest } = props;
  if (variantColor === 'default') {
    return <Tag variant="outline" size="sm" {...rest} />;
  } else {
    return <Tag variant={variantColor} size="sm" {...rest} />;
  }
};
