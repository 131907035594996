import { QBox, QIconButton, QInput, QText, QTheme } from '../..';
import React from 'react';

import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateContainer } from './DatePicker.styles';

export type QDatepickerProps = {
  value?: string | number | Date;
  onChange: (date: Date) => void;
  noPopper?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
};

export const QDatepicker: React.FC<QDatepickerProps> = ({
  value,
  onChange,
  noPopper = false,
  isDisabled = false,
  isClearable = false,
  placeholder = 'dd/MMM/yyyy',
  minDate,
  maxDate,
}) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const dateObject = value
    ? value instanceof Date
      ? value
      : new Date(value)
    : undefined;

  return (
    <DateContainer QTheme={QTheme} noPopper={noPopper}>
      <ReactDatePicker
        disabled={isDisabled}
        isClearable={isClearable}
        selected={dateObject}
        onChange={(date: Date) => onChange(date)}
        customInput={<QInput iconLeftName="Calendar" mask="99/aaa/9999" />}
        calendarStartDay={1}
        dateFormat="dd/MMM/yyyy"
        placeholderText={placeholder}
        minDate={minDate}
        maxDate={maxDate}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <>
            <QBox
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              px={2}
            >
              <QIconButton
                aria-label="previous-month"
                iconName="ChevronLeft"
                onClick={decreaseMonth}
                isDisabled={prevMonthButtonDisabled}
              />
              <QBox display="flex">
                <QBox w="100px">
                  <QText color="gray.800" weight="semibold">
                    {months[date.getMonth()]} {date.getFullYear().toString()}
                  </QText>
                </QBox>
              </QBox>
              <QIconButton
                aria-label="next-month"
                iconName="ChevronRight"
                onClick={increaseMonth}
                isDisabled={nextMonthButtonDisabled}
              />
            </QBox>
          </>
        )}
      />
    </DateContainer>
  );
};
