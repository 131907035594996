import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertIconProps,
  AlertTitle,
  CloseButton,
} from '@chakra-ui/react';
import { QBox, QStack } from '../../QLayouts';
import React from 'react';
import { getIcon } from '../..';
import { IconNameType } from '../../types/Icons';
import { QMarginProps } from '../..';

type AlertStatus = 'info' | 'warning' | 'success' | 'error';

export interface QAlertProps extends QMarginProps {
  title?: string;
  description: string | React.ReactNode;
  status: AlertStatus;
  onAlertDismissed?: () => void;
  isInline?: boolean;
}

export interface QAlertIconProps
  extends AlertIconProps,
    Pick<QAlertProps, 'status'> {}

type Colour = `${string}.${number}00`;

export const iconMapping: Record<AlertStatus, IconNameType> = {
  info: 'ExclamationCircleFill',
  success: 'CheckCircleFilled',
  error: 'FillXCircleFill',
  warning: 'FillExclamationTriangleFill',
};

const backgroundColorsMapping: Record<AlertStatus, Colour> = {
  info: 'blue.100',
  success: 'green.100',
  error: 'red.100',
  warning: 'yellow.100',
};

const colorMapping: Record<AlertStatus, Colour> = {
  info: 'blue.900',
  success: 'green.900',
  error: 'red.900',
  warning: 'yellow.900',
};

const iconColorMapping: Record<AlertStatus, Colour> = {
  info: 'blue.500',
  success: 'green.500',
  error: 'red.500',
  warning: 'yellow.500',
};

export const QAlert: React.FC<QAlertProps> = (props) => {
  const {
    description,
    title,
    status = 'info',
    onAlertDismissed,
    isInline = false,
    ...rest
  } = props;

  const children = (
    <>
      {title ? (
        <AlertTitle fontWeight="600" fontSize="14px">
          {title}
        </AlertTitle>
      ) : null}
      <AlertDescription display="block" fontWeight="400" fontSize="12px">
        {description}
      </AlertDescription>
    </>
  );

  return (
    <Alert
      status={status}
      bg={backgroundColorsMapping[status]}
      borderRadius="4px"
      py={3}
      px={4}
      alignItems="flex-start"
      {...rest}
    >
      <AlertIcon
        as={getIcon(iconMapping[status])}
        w={4}
        h={4}
        marginTop={1}
        color={iconColorMapping[status]}
      />
      {isInline ? (
        <QStack direction="row" color={colorMapping[status]}>
          {children}
        </QStack>
      ) : (
        <QBox flex="1" color={colorMapping[status]}>
          {children}
        </QBox>
      )}

      {onAlertDismissed ? (
        <CloseButton
          size="sm"
          marginLeft="auto"
          onClick={onAlertDismissed}
          color={colorMapping[status]}
        />
      ) : null}
    </Alert>
  );
};

export const QAlertBox: React.FC<
  Omit<QAlertProps, 'title' | 'description' | 'isInline'>
> = (props) => {
  const { status = 'info', onAlertDismissed, children, ...rest } = props;

  return (
    <Alert
      status={status}
      bg={backgroundColorsMapping[status]}
      color={colorMapping[status]}
      borderRadius="4px"
      py={3}
      px={4}
      {...rest}
    >
      <AlertIcon
        as={getIcon(iconMapping[status])}
        w={4}
        h={4}
        color={iconColorMapping[status]}
      />
      <QBox flex="1">{children}</QBox>
      {onAlertDismissed ? (
        <CloseButton
          position="absolute"
          right="8px"
          top="8px"
          onClick={onAlertDismissed}
        />
      ) : null}
    </Alert>
  );
};
