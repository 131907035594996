import React from 'react';
import { FormLabel, Switch, SwitchProps } from '@chakra-ui/react';
import { QBox } from '../..';

type QDefaultSwitchProps = Omit<SwitchProps, 'size'>;
type LeftSwitchProps = QDefaultSwitchProps & {
  leftLabel: string;
  id: string;
};

type RightSwitchProps = QDefaultSwitchProps & {
  rightLabel: string;
  id: string;
};

export type QSwitchProps =
  | LeftSwitchProps
  | RightSwitchProps
  | QDefaultSwitchProps;

const leftLabelSwitch = (props: LeftSwitchProps) => {
  const { leftLabel, ...rest } = props;
  return (
    <QBox display="flex" alignItems="center">
      <FormLabel mb={0} htmlFor={props.id} fontSize="sm">
        {leftLabel}
      </FormLabel>
      <Switch {...rest} size="md" />
    </QBox>
  );
};

const rightLabelSwitch = (props: RightSwitchProps) => {
  const { rightLabel, ...rest } = props;
  return (
    <QBox display="flex" alignItems="center">
      <Switch {...rest} size="md" />
      <FormLabel ml={3} mb={0} htmlFor={props.id} fontSize="sm">
        {rightLabel}
      </FormLabel>
    </QBox>
  );
};

const defaultSwitch = (props: QDefaultSwitchProps) => {
  return <Switch {...props} size="md" />;
};

export const QSwitch: React.FC<QSwitchProps> = (props) => {
  switch (true) {
    case 'leftLabel' in props:
      return leftLabelSwitch(props as LeftSwitchProps);
    case 'rightLabel' in props:
      return rightLabelSwitch(props as RightSwitchProps);
    default:
      return defaultSwitch(props as QDefaultSwitchProps);
  }
};
