import { UseToastOptions } from '@chakra-ui/react';
import { QBox, QCloseButton, QText, QIcon, DataProps } from '../..';
import React from 'react';
import { IconNameType } from '../../types/Icons';

export type QToastProps = Pick<
  UseToastOptions,
  'title' | 'description' | 'duration' | 'id'
> &
  DataProps & {
    status: 'info' | 'warning' | 'error' | 'success';
  } & ( // If replace is true, then id is required.
    | {
        id: NonNullable<UseToastOptions['id']>;
        /** If true, the toast will be replaced with the new one.
         *  An id must be provided for this. */
        replace: true;
      }
    | {
        replace?: false;
      }
  );

type ToastStyleProp = {
  bg: string;
  color: string;
  borderColor: string;
  iconColor: string;
  iconName: IconNameType;
};

export const ToastStylesMap: Record<QToastProps['status'], ToastStyleProp> = {
  info: {
    bg: 'blue.50',
    color: 'blue.900',
    borderColor: 'blue.400',
    iconColor: 'blue.500',
    iconName: 'ExclamationCircleFill',
  },
  warning: {
    bg: 'yellow.50',
    color: 'yellow.900',
    borderColor: 'yellow.400',
    iconColor: 'yellow.500',
    iconName: 'FillExclamationTriangleFill',
  },
  error: {
    bg: 'red.50',
    color: 'red.900',
    borderColor: 'red.400',
    iconColor: 'red.500',
    iconName: 'FillXCircleFill',
  },
  success: {
    bg: 'green.50',
    color: 'green.900',
    borderColor: 'green.400',
    iconColor: 'green.500',
    iconName: 'FillCheckCircleFill',
  },
};

type QToastComponentProps = Pick<
  QToastProps,
  'status' | 'title' | 'description' | keyof DataProps
> & { onClose?: () => void };

export const QToast: React.VFC<QToastComponentProps> = ({
  status,
  title,
  description,
  onClose,
  ...rest
}) => {
  const { borderColor, bg, iconName, iconColor, color } =
    ToastStylesMap[status];
  return (
    <QBox
      color={color}
      bg={bg}
      px={4}
      py={3}
      borderRadius="4px"
      borderWidth="1.5px"
      borderColor={borderColor}
      display="flex"
      flexDirection="row"
      w="356px"
      justifyContent="space-between"
      {...rest}
    >
      <QBox display="flex" flexDirection="row">
        <QBox mr={2} mt="2px" color={iconColor}>
          <QIcon iconName={iconName} />
        </QBox>
        <QBox>
          <QText fontSize="14px" fontWeight="600">
            {title}
          </QText>
          {description ? (
            <QText fontSize="12px" fontWeight="400">
              {description}
            </QText>
          ) : null}
        </QBox>
      </QBox>
      <QCloseButton size="sm" color={color} onClick={onClose} />
    </QBox>
  );
};
