import React, { useState } from 'react';
import { excludeChildrenOfType, getChildrenOfType } from '../..';
import { QStepVariantOptions } from './components';
import { QStepperContextProvider } from './StepperContext';

export type QStepType = {
  title: string;
  description?: string;
  children: React.ReactNode;
  variant: Exclude<QStepVariantOptions, 'active'>;
};

export type ContextQStepType = {
  index: number;
} & QStepType;

export type QStepperProps = {
  activeStep: number;
  onStepItemClicked: (index: number) => void;
};

export const QStepper: React.FC<QStepperProps> = ({
  activeStep,
  onStepItemClicked,
  children,
}) => {
  const [steps, setSteps] = useState<ContextQStepType[] | []>([]);

  const childrenArray = React.Children.toArray(children);

  const panelActionButtons = getChildrenOfType(
    childrenArray,
    'QStepperPanelActions',
  );
  const notPAnelActionButtons = excludeChildrenOfType(
    childrenArray,
    'QStepperPanelActions',
  );
  return (
    <QStepperContextProvider
      value={{
        activeStep,
        steps,
        setSteps,
        onStepItemClicked,
      }}
    >
      {notPAnelActionButtons}
      {panelActionButtons}
    </QStepperContextProvider>
  );
};
